/***************************************************************************************************************************
 * @file: /Users/jason/Sites/cotf/src/hooks/shuffleChoices.ts
 * -----------------------------------------------------------------------------------------------------------------------------------------------
 * @description: 
 * ---------------------------------------------------------------------------------------------------------------------------------------------
 * @functionality: 
 * ---------------------------------------------------------------------------------------------------------------------------------------------
 * Created by: Jason McCoy
 * Created on: 01/17/2023
 * ---------------------------------------------------------------------------------------------------------------------------------------------
 * Last Updated by: Jason McCoy
 * Last Updated on: 01/17/2023
 * ---------------------------------------------------------------------------------------------------------------------------------------------
 * Changes made: 
 * ---------------------------------------------------------------------------------------------------------------------------------------------
 * Notes: 
 ***************************************************************************************************************************/

import { useMemo } from 'react';

const shuffleArray = <T>(array: T[]): T[] => {
  let newArray = [...array];
  for (let i = newArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
  }
  return newArray;
};

const useShuffledChoices = (choices: string[]): string[] => {
  const shuffledChoices = useMemo(() => shuffleArray(choices), [choices]);
  return shuffledChoices;
};

export default useShuffledChoices;

  